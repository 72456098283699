import React, { Component } from 'react';

class News extends Component {

    componentDidMount() {
        console.log('News componentDidMount')
    }

    render() {

        return (

            <div className="news">
                <div className="container">
                    <div className="section_header">
                        <h3>News...</h3>
                    </div>
                </div>
            </div>

        );
    }
}

export default News;
