// need to clean up state/props and data passing

import React, { Component } from "react";

import "./App.css";
import "./components/css/compiled/main.css";

import NavBar from "./components/NavBar";
import BigNavButtons from "./components/BigNavButtons";
import AlbumCarousel from "./components/AlbumCarousel";
import Discography from "./components/Discography";
import AlbumArt from "./components/AlbumArt";
import Media from "./components/Media";
import News from "./components/News";
import Neil from "./components/Neil";
import MoreBarn from "./components/MoreBarn";
import Lyrics from "./components/Lyrics";
import Contact from "./components/Contact";
import About from "./components/About";
import Footer from "./components/Footer";

// Configuration params for local, dev, test, prod environments
import config from "./data/config.json";

// Neil Young discography, websites, media, news, etc.

class App extends Component {
  constructor(props) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    // this.handleNavBarClick = this.handleNavBarClick.bind(this);
    this.handleBigNavButtonClick = this.handleBigNavButtonClick.bind(this);
    this.state = {
      environment: null,
      nydata: null,
      showBigNavButtons: true,
      showCarousel: true,
      showDiscography: false,
      showAlbumArt: false,
      showMedia: false,
      showNews: false,
      showNeil: false,
      showMoreBarn: false,
      showLyrics: false,
      showContact: false,
      showAbout: false
    };
    this.comps = this.state;
  }

  componentDidMount() {

    // Data service - for now just fetch a file from CDN
    // then maybe implement a REST API and then GraphQL
    // get discography from https://cdn.twistedroad.org/data/nydata-v3.json

    // debug why getEnvironment has to both setState and return a value
    this.env = this.getEnvironment();
    console.log('env3', this.env);
    this.getData(this.env.dataLocation);

    console.log("App componentDidMount");
  }

  // Update UI components based on BigMenuButton clicks
  handleBigNavButtonClick(e) {
    console.log("App handleBigNavButtonClick(e)", e);
    this.setState({
      showBigNavButtons: e.showBigNavButtons,
      showCarousel: e.showCarousel,
      showDiscography: e.showDiscography,
      showAlbumArt: e.showAlbumArt,
      showMedia: e.showMedia,
      showNews: e.showNews,
      showNeil: e.showNeil,
      showMoreBarn: e.showMoreBarn,
      showLyrics: e.showLyrics,
      showContact: e.showContact,
      showAbout: e.showAbout
    });
    console.log("App handleBigNavButtonClick setState", this.state);
  }

  render() {

    console.log('render state', this.state);

    return (

      <div className="App" >
        <NavBar handleNavBarClick={this.handleNavBarClick} />
        {this.state.showCarousel && <AlbumCarousel />
        }
        {
          this.state.showBigNavButtons && (
            <BigNavButtons
              handleBigNavButtonClick={this.handleBigNavButtonClick}
            />
          )
        }
        {
          this.state.showDiscography && (
            <Discography discography={this.state} />
          )
        }
        {this.state.showAlbumArt && <AlbumArt />}
        {this.state.showMedia && <Media />}
        {this.state.showNews && <News />}
        {this.state.showNeil && <Neil environment={this.environment} />}
        {this.state.showMoreBarn && <MoreBarn />}
        {this.state.showLyrics && <Lyrics />}
        {this.state.showContact && <Contact />}
        {this.state.showAbout && <About />}
        < Footer />
      </div >
    );
  }

  // Get config params for specific environment
  getEnvironment() {
    switch (config.config.common.environment) {
      case "local":
        console.log("config.local", config.config.local);
        return { ...config.config.common, ...config.config.local };
      case "dev":
        console.log("config.dev", config.config.dev);
        this.setState({ environment: { ...config.config.common, ...config.config.dev } });
        return { ...config.config.common, ...config.config.dev };
      case "test":
        console.log("config.test", config.config.test);
        return { ...config.config.common, ...config.config.test };
      case "prod":
        console.log("config.prod", config.config.prod);
        return { ...config.config.common, ...config.config.prod };
      default:
        console.log("No config found");
    }
  }

  getData(dataUrl) {
    fetch(dataUrl)
      .then(response => response.json())
      .then(response => {
        console.log('getData response:', response);
        this.setState({
          nydata: response
        })
        return response;
      });
  }
}

export default App;