import React, { Component } from 'react';

class MoreBarn extends Component {

    componentDidMount() {
        console.log('MoreBarn componentDidMount')
    }

    render() {

        return (

            <div className="morebarn">
                <div className="container">
                    <div className="section_header">
                        <h3>More Barn...</h3>
                    </div>
                </div>
            </div>

        );
    }
}

export default MoreBarn;
