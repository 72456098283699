import React, {Component} from 'react';

import btnDiscography from './img/btnDiscography_white_275x158.jpg';
import btnMedia from './img/btnMedia_white_275x158.jpg';
import btnNews from './img/btnNews_white_275x158.jpg';
import btnNeil from './img/btnNeil_white_275x158.jpg';
import btnMoreBarn from './img/btnMoreBarn_white_275x158.jpg';
import btnLyrics from './img/btnLyrics_white_275x158.jpg';


class BigNavButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        // This binding is necessary to make `this` work in the callback
        this.handleBigNavButtonClick = this.handleBigNavButtonClick.bind(this);
    }

    componentDidMount() {
        console.log('BigNavButtons componentDidMount')
    }

    handleBigNavButtonClick(comps) {
        console.log('BigNavButtons handleClick(comps)', comps);
        this.props.handleBigNavButtonClick(comps);
    }


    render() {

        return (

            <div>
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-sm-12">
                            <div className="section_header">
                                <h3>Stuff I find innaresting...</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <div className="col-sm-4">
                            <div>
                                <a href="#" onClick={() => this.handleBigNavButtonClick({showBigNavButtons: false, showDiscography: true})}>
                                    <img src={btnDiscography}
                                         className="img-fluid max-width: 100%; height: auto; pl-3 pr-3"
                                         alt="Discography button"/>
                                </a>
                            </div>
                            <div className="btn_desc pl-3">Discography</div>
                        </div>
                        <div className="col-sm-4">
                            <div>
                                <a href="#" onClick={() => this.handleBigNavButtonClick({showBigNavButtons: false, showMedia: true})}>
                                    <img src={btnMedia}
                                         className="img-fluid max-width: 100%; height: auto; pl-3 pr-3"
                                         alt="Media button"/>
                                </a>
                            </div>
                            <div className="btn_desc pl-3">Music & Video</div>
                        </div>
                        <div className="col-sm-4">
                            <div>
                                <a href="#" onClick={() => this.handleBigNavButtonClick({showBigNavButtons: false, showNews: true})}>
                                <img src={btnNews}
                                     className="img-fluid max-width: 100%; height: auto; pl-3 pr-3"
                                     alt="News button"/>
                                </a>
                            </div>
                            <div className="btn_desc pl-3">News</div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <div className="col-sm-4">
                            <div>
                                <a href="#" onClick={() => this.handleBigNavButtonClick({showBigNavButtons: false, showNeil: true})}>
                                <img src={btnNeil}
                                     className="img-fluid max-width: 100%; height: auto;  pl-3 pr-3"
                                     alt="Neil button"/>
                                </a>
                            </div>
                            <div className="btn_desc pl-3">Neil Young</div>
                        </div>
                        <div className="col-sm-4">
                            <div>
                                <a href="#" onClick={() => this.handleBigNavButtonClick({showBigNavButtons: false, showMoreBarn: true})}>
                                <img src={btnMoreBarn}
                                     className="img-fluid max-width: 100%; height: auto; pl-3 pr-3"
                                     alt="More Barn button"/>
                                </a>
                            </div>
                            <div className="btn_desc pl-3">More Fan Sites</div>
                        </div>
                        <div className="col-sm-4">
                            <div>
                                <a href="#" onClick={() => this.handleBigNavButtonClick({showBigNavButtons: false, showLyrics: true})}>
                                <img src={btnLyrics}
                                     className="img-fluid max-width: 100%; height: auto; pl-3 pr-3"
                                     alt="Lyrics button"/>
                                </a>
                            </div>
                            <div className="btn_desc pl-3">Lyrics</div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default BigNavButtons;
