import React, { Component } from 'react';

class Neil extends Component {

    componentDidMount() {
        console.log('Neil componentDidMount')
    }

    render() {

        let nyimage = "NY_MVCA_1993.jpg";
        let nyimageurl = this.props.environment.imagesLocation + nyimage;

        return (

            <div className="neil">
                <div className="container">
                    <div className="section_header">
                        <h3>Neil Young I find very innaresting...</h3>
                    </div>

                    <div className="row bio">
                        <div className="col-sm-4 bio-photo">
                            <img className="img-fluid" alt="Neil Young" src={nyimageurl} />
                        </div>
                        <div className="col-sm-8 bio-text">
                            Neil Percival Young OC, OM (born November 12, 1945) is a Canadian singer-songwriter and musician.
                            He began performing in a group covering Shadows instrumentals in Canada in 1960, before moving to
                            California in 1966, where he co-founded the band Buffalo Springfield along with Stephen Stills and
                            Richie Furay, later joining Crosby, Stills & Nash as a fourth member in 1969. He forged a successful
                            and acclaimed solo career, releasing his first album in 1968; his career has since spanned over 45
                            years and 35 studio albums, with a continual and uncompromising exploration of musical styles.
                            The Rock and Roll Hall of Fame website describes Young as "one of rock and roll’s greatest songwriters
                            and performers". He has been inducted into the Hall of Fame twice: first as a solo artist in 1995,
                            and second as a member of Buffalo Springfield in 1997.
                            <p>&nbsp;</p><p>&nbsp;</p>
                            <div className="copyright">
                                image copyright &#0169; 1993 Tim Mosenfelder / Hulton Archive / Getty Images <br />
                                used with permission
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        );
    }
}

export default Neil;
