import React, { Component } from "react";

import Slider from "../../node_modules/react-slick";
import slick from "../../node_modules/slick-carousel/slick/slick.css";
import slicktheme from "../../node_modules/slick-carousel/slick/slick-theme.css";

class AlbumCarousel extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("AlbumCarousel componentDidMount");
  }

  render() {
    const settings = {
      autoplay: true,
      arrows: false,
      dots: false,
      infinite: true,
      speed: 400,
      slidesToShow: 3,
      slidesToScroll: 3,
      adaptiveHeight: true
    };

    const backgroundimage =
      "https://cdn.twistedroad.org/images/redwoodsfog.jpg";

    return (
      <div className="carousel">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Slider {...settings}>
                <img
                  src="https://s3-us-west-2.amazonaws.com/nych-images/albumart/300px/everybodyknows.jpg"
                  className="albumcarouselalbum"
                />
                <img
                  src="https://s3-us-west-2.amazonaws.com/nych-images/albumart/300px/bluenotecafe.jpg"
                  className="albumcarouselalbum"
                />
                <img
                  src="https://s3-us-west-2.amazonaws.com/nych-images/albumart/300px/afterthegoldrush.jpg"
                  className="albumcarouselalbum"
                />
                <img
                  src="https://s3-us-west-2.amazonaws.com/nych-images/albumart/300px/harvest.jpg"
                  className="albumcarouselalbum"
                />
                <img
                  src="https://s3-us-west-2.amazonaws.com/nych-images/albumart/300px/weld.jpg"
                  className="albumcarouselalbum"
                />
                <img
                  src="https://s3-us-west-2.amazonaws.com/nych-images/albumart/300px/freedom.jpg"
                  className="albumcarouselalbum"
                />
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AlbumCarousel;
