import React, { Component } from "react";
import ReleaseDetails from "./ReleaseDetails"

class Release extends Component {
  constructor(props) {
    super(props);

    this.state = { release: this.props.release, environment: this.props.environment, showReleaseDetailsToggle: false, isLoaded: false };
  }

  showReleaseDetails = () => {

    this.getReleaseDetails(this.state.release.discogs_release_id);

    this.setState({ ...this.state, showReleaseDetailsToggle: true });
  }

  getReleaseDetails = (album_id) => {

    const release_url = this.state.environment.discogsReleasesAPI + album_id;

    fetch(release_url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            releasedetails: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {
    console.log("Release componentDidMount");
  }

  render() {

    const { error, isLoaded, release, releasedetails } = this.state;

    let title = release.nya_title;
    let artist = release.nya_artist;
    let year = release.nya_year;
    let albumart = this.state.environment.albumartLocation300px + release.album_art;

    console.log(this.state);

    return (
      <div>
        <div className="container-fluid albumbox">
          <div className="row">
            <div className="col-sm-4 albumart">
              <img className="img-responsive" src={albumart}
                height="300px" width="300px"
                alt="album cover art"
                onClick={this.showReleaseDetails}
              />
            </div>
            <div className="col-xs-7 albumtitleartistyear">
              <div className="text-left">{title}</div>
              <div className="text-left">{artist}, {year}</div>
            </div>
          </div>
          {this.state.showReleaseDetailsToggle && isLoaded && <ReleaseDetails releasedetails={releasedetails} />}
        </div>
      </div>
    );
  }
}

export default Release;
