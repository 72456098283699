import React, { Component } from "react";

class ReleaseDetails extends Component {
    constructor(props) {
        super(props);

        this.state = { releasedetails: this.props.releasedetails };
    }

    componentDidMount() {
        console.log("ReleaseDetails componentDidMount");

    }

    render() {

        const { releasedetails } = this.state;
        console.log(releasedetails);

        const tracklist = releasedetails.tracklist.map((track) => <div>{track.position} {track.title} {track.duration}</div>);
        const extraartists = releasedetails.extraartists.map((artist) => <div>{artist.name}, {artist.role}</div>);
        const labels = releasedetails.labels.map((label) => <div>{label.name} {label.catno} </div>);
        const released = <div>released: {releasedetails.released} </div>;
        const country = <div>country: {releasedetails.country} </div>;
        const notes = <div>notes: {releasedetails.notes} </div>;
        const release_uri = <div>discogs.com release: <a href={releasedetails.uri} target="_blank">{releasedetails.uri}</a></div>
        const master_uri = <div>discogs.com master: <a href="http://www.discogs.com/master/"
            target="_blank"> http://www.discogs.com/master/{releasedetails.master_id}</a></div>
        const discogs = <div>data courtesy of <a href="http://www.discogs.com" target="_blank">discogs.com</a></div>

        return (
            <div className="albumdetail">
                <div className="container-fluid">
                    <div className="row align-items-left">
                        <div className="col-md-5">
                            {tracklist}
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-6">
                            {extraartists}
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-12">
                            {labels}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {released}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {country}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {notes}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {release_uri}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {master_uri}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {discogs}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReleaseDetails;
