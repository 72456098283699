import React, { Component } from "react";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    console.log("NavBar componentDidMount");
  }

  handleClick(comps) {
    console.log("NavBar handleNavBarClick(comps)", comps);
    this.props.handleNavBarClick(comps);
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg fixed-top">
          <div className="container-fluid">
            <a className="navbar-brand pl-4" href="index.html">
              Twisted Road...
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="/navbarsExample05"
              aria-controls="navbarsExample05"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarsExample05">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    href="#"
                    onClick={() =>
                      this.handleClick({
                        showBigNavButtons: true,
                        showCarousel: true
                      })
                    }
                  >
                    HOME<span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href=""
                    id="dropdown05"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    PAGES
                  </a>
                  <div className="dropdown-menu" aria-labelledby="dropdown05">
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        this.handleClick({
                          showBigNavButtons: false,
                          showCarousel: false,
                          showDiscography: true
                        })
                      }
                    >
                      Discography
                    </a>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        this.handleClick({
                          showBigNavButtons: false,
                          showCarousel: false,
                          showAlbumArt: true
                        })
                      }
                    >
                      Album Art
                    </a>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        this.handleClick({
                          showBigNavButtons: false,
                          showCarousel: false,
                          showMedia: true
                        })
                      }
                    >
                      Media
                    </a>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        this.handleClick({
                          showBigNavButtons: false,
                          showCarousel: false,
                          showNews: true
                        })
                      }
                    >
                      News
                    </a>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        this.handleClick({
                          showBigNavButtons: false,
                          showCarousel: false,
                          showNeil: true
                        })
                      }
                    >
                      Neil
                    </a>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        this.handleClick({
                          showBigNavButtons: false,
                          showCarousel: false,
                          showMoreBarn: true
                        })
                      }
                    >
                      More Barn
                    </a>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        this.handleClick({
                          showBigNavButtons: false,
                          showCarousel: false,
                          showLyrics: true
                        })
                      }
                    >
                      Lyrics
                    </a>
                  </div>
                </li>
                <li>
                  <a
                    className="nav-link"
                    href="#"
                    onClick={() =>
                      this.handleClick({
                        showBigNavButtons: false,
                        showContact: true
                      })
                    }
                  >
                    CONTACT US
                  </a>
                </li>
                <li>
                  <a
                    className="nav-link pr-4"
                    href="#"
                    onClick={() =>
                      this.handleClick({
                        showBigNavButtons: false,
                        showAbout: true
                      })
                    }
                  >
                    ABOUT...
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default NavBar;
