import React, { Component } from 'react';


class Lyrics extends Component {

    componentDidMount() {
        console.log('Lyrics componentDidMount');
    }
    render() {

        return (

            <div className="lyrics">
                <div className="container">
                    <div className="section_header">
                        <h3>Lyrics I find innaresting...</h3>
                    </div>
                </div>

                <div className="twistedroad" align="center">
                    <br />
                    <br />
                    Twisted Road <br />
                    <br />
                    First time I heard "Like A Rolling Stone" <br />
                    I felt that magic and took it home <br />
                    Gave it a twist and made it mine <br />
                    But nothin' was as good as the very first time <br />
                    Poetry rollin' off his tongue <br />
                    Like Hank Williams chewin' bubble gum <br />
                    Askin' me "How does it feel?" <br />
                    <br />
                    First time I heard "Like A Rolling Stone" <br />
                    I felt that magic and took it home <br />
                    <br />
                    Walkin' with the Devil on a twisted road <br />
                    Listenin' to The Dead on the radio <br />
                    That old time music used to soothe my soul <br />
                    If I ever get home I'm gonna let the good times roll <br />
                    Let the good times roll <br />
                    <br />
                    Flying down a road in a dream of mine <br />
                    Two lane tour through a state of mind <br />
                    Singin' in the place where I first saw Roy <br />
                    Playin' that place gave me so much joy <br />
                    Brand new tune with familiar chords <br />
                    Flashbulbs poppin' at the stage door <br />
                    All the time lookin' for somethin' new <br />
                    <br />
                    Singin' in the place where I first saw Roy <br />
                    Playin' that place gave me so much joy <br />
                    <br />
                    Walkin' with the Devil on a twisted road <br />
                    Listenin' to The Dead on the radio <br />
                    That old time music used to soothe my soul <br />
                    If I ever get home I'm gonna let the good times roll <br />
                    Let the good times roll <br />
                    <br />
                    Walkin' with the devil on a twisted road <br />
                    Listenin' to The Dead on the radio <br />
                    That old time music used to soothe my soul <br />
                    If I ever get home I'm gonna let the good times roll <br />
                    Let the good times roll <br />
                    Let the good times roll <br />
                    Let the good times roll <br />
                    Let the good times roll <br />
                    <br />
                    <br />

                    <div className="copyright">Written by Neil Young <br /> Copyright 2012 Silver Fiddle Music (ASCAP)</div>
                    <br />
                    <br />
                </div>
            </div>

        );
    }
}

export default Lyrics;
