import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log("Footer componentDidMount");
  }

  render() {
    return (
      <footer id="footer" className="footer">
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-xs-12">
              {" "}
              Twisted Road &nbsp; &middot; &nbsp; twistedroad.org &nbsp;
              &middot; &nbsp; info@twistedroad.org
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
