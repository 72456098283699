import React, { Component } from "react";

class Contact extends Component {

  componentDidMount() {
    console.log("Contact componentDidMount");
  }

  render() {
    return (
      <div className="about">
        <div className="container">
          <div className="section_header">
            <h3>You I find innaresting...</h3>
          </div>

          <div>
            <h4>twistedroad.org would love to hear from you...</h4>
            <p>Give us a shout at innaresting@twistedroad.org</p>
            <br />
          </div>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default Contact;
