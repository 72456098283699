import React, {Component} from 'react';

class AlbumArt extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        console.log('AlbumArt componentDidMount')
    }

    render() {

        return (

            <div className="albumart">
                <div className="container">
                    <div className="section_header">
                        <h3>AlbumArt...</h3>
                    </div>
                </div>
            </div>

        );
    }
}

export default AlbumArt;
