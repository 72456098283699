import React, { Component } from "react";

import Release from "./Release";

class Discography extends Component {
  constructor(props) {
    super(props);
    console.log('Discography props', props);
  }

  componentDidMount() {
    console.log("Discography componentDidMount");
  }

  render() {
    // need to pass this.state.environment.
    const discography = this.props.discography.nydata.discography;
    const environment = this.props.discography.environment;
    const releaseList = discography.map((release) =>
      < Release release={release} environment={environment} key={release.nya_album_id} />
    );

    return (
      <div>
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-sm-12">
              {releaseList}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Discography;
